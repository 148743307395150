import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import Link from '../components/framework/link';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

import Logo from '../images/framework/logo.svg';

import { CalendarDay } from 'react-bootstrap-icons';
import { Pencil } from 'react-bootstrap-icons';

const IndexPage = ({ location }) => {
	const { t } = useTranslation('index');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>

			<div className="container container-fullscreen">
				<div className="align-items-center g-6 row justify-content-center text-center text-nowrap">
					<div className="col">
						<Link to="/book">
							<div className="align-middle btn-index">
								<div className="ratio ratio-1x1">
									<figure className="figure">
										<CalendarDay size="70%" />
										<figcaption className="text-push text-dark figure-caption mt-2">
											{t('t2')}
										</figcaption>
									</figure>
								</div>
							</div>
						</Link>
					</div>

					<div className="col">
						<Link to="/register">
							<div className="align-middle btn-index">
								<div className="ratio ratio-1x1">
									<figure className="figure">
										<Pencil size="70%" />
										<figcaption className="text-push figure-caption text-dark mt-2">
											{t('t3')}
										</figcaption>
									</figure>
								</div>
							</div>
						</Link>
					</div>

					<Link to="/information">
						<img className="logo mx-auto text-center pb-5" src={Logo} />
					</Link>
				</div>
			</div>
		</Framework>
	);
};

IndexPage.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(IndexPage);

export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					data
					language
					ns
				}
			}
		}
	}
`;
